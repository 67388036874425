<template>
  <div v-if="isAuthenticated" class="profile-menu">
    <StSidebarList :level="1">
      <StSidebarItem
        :level="0"
        :label="t('dashboard.profile')"
        icon="user-solid"
        link="/personal/profile"
        data-t="profile-link"
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.wallet')"
        icon="wallet-solid"
        link="/personal/wallet"
        data-t="wallet-link"
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.transactions')"
        icon="transactions"
        link="/personal/transactions"
        data-t="transactions-link"
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.myBets')"
        icon="ticket-solid"
        link="/personal/bets"
        :counter="betCounter"
        data-t="bets-link"
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.bonuses')"
        icon="gift-solid"
        link="/bonuses"
        :counter="bonusesCount"
        data-t="bonuses-link"
      />
      <StSidebarItem
        :level="0"
        :label="t('dashboard.verification')"
        icon="id-card-clip-solid"
        link="/personal/kyc"
        data-t="verification-link"
        :badge="kycLevelName"
      />
    </StSidebarList>
  </div>
</template>

<script setup lang="ts">
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useBonusesCountStore } from '@st/bonuses/stores/useBonusesCountStore'
import { useKycLevel } from '@st/user/composables/useKycLevel'

const { t } = useI18n()

const { betCounter } = storeToRefs(useActiveBetsStore())
const { kycLevelName } = useKycLevel()
const { isAuthenticated } = storeToRefs(useUserStore())

const { bonusesCount } = storeToRefs(useBonusesCountStore())
</script>

<style scoped>
.profile-menu {
  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);
}
</style>
